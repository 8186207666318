.Features {
  padding: 100px 0;
  background-color: #f5f5f5;
}

.Features-icon {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  border-radius: 50%;
}

.Features-icon img {
  max-width: 100%;
  max-height: 100%;
}

.Features-title {
  margin-top: 20px;
  font-weight: bold;
  text-align: center;
}

.Features-description {
  text-align: center;
  margin-top: 10px;
  font-size: 16px;
  color: #333;
}